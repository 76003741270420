import React, { useState } from 'react';
import { Link } from 'gatsby';

export default function Mobilenav() {
    const [isOpen, setOpen] = useState('false');

    const menuToggle = () => {
        setOpen(!isOpen);
    };

    return (
        <div>
            <button
                className={isOpen ? 'menu-toggle' : 'menu-toggle open'}
                onClick={menuToggle}
            >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </button>
            <nav className={isOpen ? 'app-mobmenu' : 'app-mobmenu open'}>
                <ul>
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>
                        <Link to="/about" activeClassName="active">
                            About Us
                        </Link>
                    </li>
                    <li>
                        <Link to="/services" activeClassName="active">
                            Services
                        </Link>
                    </li>
                    <li>
                        <Link to="/portal" activeClassName="active">
                            Client Portal
                        </Link>
                    </li>
                    <li>
                        <Link to="/contact" activeClassName="active">
                            Contact
                        </Link>
                    </li>
                </ul>
            </nav>
            <div className={isOpen ? 'underlay' : 'underlay open'}></div>
        </div>
    );
}
