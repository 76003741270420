import React from 'react';
import Header from './header';
import Footer from './footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/main.scss';

export default function Layout(props) {
    return (
        <div id="app">
            <Header />
            {props.children}
            <Footer />
            <ToastContainer
                position="bottom-center"
                style={{ width: '600px' }}
            />
        </div>
    );
}
