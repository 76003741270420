import React from 'react';
import { Link } from 'gatsby';

export default function Navigation() {
    return (
        <nav className="app-menu">
            <ul>
                <li>
                    <Link to="/">Home</Link>
                </li>
                <li>
                    <Link to="/about" activeClassName="active">
                        About Us
                    </Link>
                </li>
                <li>
                    <Link to="/services" activeClassName="active">
                        Services
                    </Link>
                </li>
                <li>
                    <Link to="/portal/dashboard" activeClassName="active">
                        Client Portal
                    </Link>
                </li>
                <li>
                    <Link to="/contact" activeClassName="active">
                        Contact
                    </Link>
                </li>
            </ul>
        </nav>
    );
}
